/* eslint-disable default-param-last */
/* eslint-disable no-case-declarations */

import { SetAnswersQuestionStorage } from "../../services/Storage/AnswersQuiz";
import { QuizActions } from "./actions";

const INITIAL_STATE = {
    responseQuiz: [],
    reviewAnswersQuestions: false,
    configStateSteps: false,
};

export const QuizReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case QuizActions.INCLUDE_INTEM_QUESTION_ACTION:
            let changed = false;

            const dataResponse = state.responseQuiz.map((response) => {
                if (action.questionId === response.questionId) {
                    changed = true;

                    switch (action.questionType) {
                        case "S":
                            return {
                                questionId: action.questionId,
                                responseId: action.responseId,
                                type: action.questionType,
                            };

                        case "T":
                            return {
                                questionId: action.questionId,
                                responseId: action.textValue,
                                type: action.questionType,
                            };

                        case "C":
                            if (action.responseText === "") {
                                return {
                                    questionId: action.questionId,
                                    responseId: action.arrayAnswres,
                                    type: action.questionType,
                                };
                            }

                            return {
                                questionId: action.questionId,
                                responseId: action.arrayAnswres,
                                responseText: action.responseText,
                                type: action.questionType,
                            };

                        default:
                            return {
                                questionId: action.questionId,
                                responseId: action.responseId,
                                type: action.questionType,
                            };
                    }
                }

                return response;
            });

            if (!changed) {
                switch (action.questionType) {
                    case "S":
                        dataResponse.push({
                            questionId: action.questionId,
                            responseId: action.responseId,
                            type: action.questionType,
                        });

                        break;

                    case "T":
                        dataResponse.push({
                            questionId: action.questionId,
                            responseId: action.textValue,
                            type: action.questionType,
                        });

                        break;

                    case "C":
                        if (action.responseText === "") {
                            dataResponse.push({
                                questionId: action.questionId,
                                responseId: action.arrayAnswres,
                                type: action.questionType,
                            });
                        } else {
                            dataResponse.push({
                                questionId: action.questionId,
                                responseId: action.arrayAnswres,
                                responseText: action.responseText,
                                type: action.questionType,
                            });
                        }

                        break;

                    default:
                        dataResponse.push({
                            questionId: action.questionId,
                            responseId: action.responseId,
                            type: action.questionType,
                        });

                        break;
                }
            }

            SetAnswersQuestionStorage(dataResponse);

            return { ...state, responseQuiz: dataResponse };

        case QuizActions.REVIEW_ALL_ANSWERS_QUESTIONS:
            return {
                ...state,
                reviewAnswersQuestions: action.reviewAnswersQuestions,
            };

        case QuizActions.RESET_STEPS_ACTION_BUTTONS:
            return {
                ...state,
                configStateSteps: !state.configStateSteps,
            };

        case QuizActions.POPULATE_ANSWERS_QUESTIONS:
            return {
                ...state,
                responseQuiz: action.responseQuiz,
            };

        case QuizActions.CLEAR_ALL_STATE:
            return {
                ...state,
                responseQuiz: [],
                reviewAnswersQuestions: false,
            };

        default:
            return { ...state };
    }
};
