const KEY = "@ANSWERS_QUESTIONS";

export const SetAnswersQuestionStorage = (answers) => {
    try {
        const jsonValue = JSON.stringify({ answers });
        localStorage.setItem(KEY, jsonValue);

        return true;
    } catch (e) {
        return false;
    }
};

export const GetAnswersQuestionStorage = () => {
    try {
        const result = JSON.parse(localStorage.getItem(KEY));

        return result.answers;
    } catch (e) {
        return [];
    }
};

export const ClearAnswersQuestionStorage = () => {
    try {
        localStorage.removeItem(KEY);

        return true;
    } catch (error) {
        return false;
    }
};
