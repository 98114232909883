import { BrowserRouter, Routes, Route } from "react-router-dom";
import loadable from "@loadable/component";

import { RouteURLs } from "./RouteURLs";

const Home = loadable(() => import("../Pages/Home"));
const Finnaly = loadable(() => import("../Pages/Finnaly"));
const Introduction = loadable(() => import("../Pages/Introduction"));
const Question = loadable(() => import("../Pages/Questions"));
const Graphs = loadable(() => import("../Pages/graphs"));

const NotFound = loadable(() => import("../Pages/NotFound"));

const RoutesApp = () => (
    <BrowserRouter>
        <Routes>
            <Route path={RouteURLs.home} element={<Home />} />
            <Route path={RouteURLs.finnaly} element={<Finnaly />} />
            <Route path={RouteURLs.introduction} element={<Introduction />} />
            <Route path={RouteURLs.questions} element={<Question />} />
            <Route path={RouteURLs.graphs} element={<Graphs />} />

            <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
);

export default RoutesApp;
